<template>
  <v-col>
    <v-select
      v-model="model"
      :items="parceiros"
      item-text="trade_name"
      return-object
      label="Selecionar parceiro"
      dense
    >
    </v-select>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Perfil from '@/utils/perfil'

export default {
  name: 'SelecionarParceiro',
  props: {
    icon: {
      type: String,
    },
    value: {
      required: true,
    },
  },
  components: {},
  async created() {
    await this.getContratos(Perfil.Parceiro)
  },
  computed: {
    ...mapGetters('contratos', ['parceiros']),
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions('contratos', ['getContratos'])
  },
}
</script>
