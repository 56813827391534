<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>Filtros</span>
      <div>
        <v-btn
          class="mx-2"
          title="Configurar filtros"
          color="primary"
          @click="handleShowConfig"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          title="Filtrar"
          color="secondary"
          @click="handleShowFilter"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-dialog v-model="showFilter" max-width="1200px">
      <parceiro-list-filtro-modal
        v-if="showFilter"
        @close="showFilter = false"
        @search="search"
      />
    </v-dialog>
    <v-dialog v-model="showConfig" max-width="500px">
      <parceiro-list-filtro-config
        v-if="showConfig"
        @close="showConfig = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ParceiroListFiltroModal from './ParceiroListFiltroModal/Componente.vue'
import ParceiroListFiltroConfig from './ParceiroListFiltroConfig/Componente.vue'

export default {
  name: 'ParceiroListFiltro',
  components: {
    ParceiroListFiltroModal,
    ParceiroListFiltroConfig,
  },
  data() {
    return {
      showFilter: false,
      showConfig: false,
      loading: false,
    }
  },

  methods: {
    handleShowFilter() {
        this.showFilter = true
        this.showConfig = false
    },
    handleShowConfig() {
      this.showFilter = false
      this.showConfig = true
    },
    async search() {
      this.showFilter = false
      this.$emit('search')
    },
  },
}
</script>
