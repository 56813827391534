import { mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from '@/utils/relatorioParceiros'

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('contratos', ['terminaisFornecedores']),

  showData() {
    return this.params.data_inicio.visible || this.params.data_fim.visible
  },
  showContratos() {
    return this.params.servico?.visible || this.params.terminal?.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showContratos)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
}
