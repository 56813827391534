<template>
  <div>
    <div class="mt-4">
      <v-card-title>
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-overlay v-model="carregandoDados">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="registros"
        :footer-props="{
          itemsPerPageAllText: `Todos (${registros.length})`,
          itemsPerPageOptions: [50, 100, 150, 200, -1],
        }"
      >
        <template v-slot:[`item.valor`]="{ item }">
          {{ toReal(item.valor) || toReal(0) }}
        </template>
      </v-data-table>
      <download-excel
        name="servicosParceiros.xls"
        :data="registros"
        :fields="fields_excel"
      >
        <v-btn color="info" class="w-100 mt-5">
          <v-icon class="mr-2" left dark> mdi-download </v-icon>
          Baixar Planilha</v-btn
        >
      </download-excel>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'ListagemRelatorioParceiro',

  mixins: [toReal],

  data() {
    return {
      registros: [],
      search: '',
      carregandoDados: false,
    }
  },
  computed: {
    ...mapState('relatorioParceiro', ['params']),
    headers() {
      if (this.registros.length > 0) {
        let header = []
        Object.keys(this.registros[0]).forEach((item) => header.push({text:item, value:item}))
        return header
      } else {
        return []
      }
    },
    fields_excel() {
      if (this.registros.length > 0) {
        let header = {}
        Object.keys(this.registros[0]).forEach((item) => header[item]= `${item}`)
        return header
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('contratos', [
      'getRelatorioParceiro',
    ]),
    ...mapActions('template', ['errorMessage']),
    async getReport() {
      if (!Object.keys(this.params).length) return
      this.carregandoDados = true
      try {
        this.registros =  await this.getRelatorioParceiro({
          ...this.params,
        })
      } catch (e) {
        if (e.response?.status === 404) {
          this.errorMessage('Página não encontrada')
        } else {
          this.errorMessage('Algum erro. Contactar o suporte.')
        }
      }
      this.carregandoDados = false
    },
  },
}
</script>
