<template>
  <v-card class="pa-2">
    <v-card-title class="d-flex justify-space-between">
      <span>Configuração</span>
      <v-btn color="error" @click="clear">Limpar</v-btn>
    </v-card-title>
    <v-card-text>
      <v-treeview
        v-model="optionsSelected"
        selectable
        :items="options"
      ></v-treeview>
      <span class="error--text"
        >*Somente os itens selecionados aparecerão na tela de filtro</span
      >
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn plain @click="close">Cancelar</v-btn>
      <v-btn color="primary" class="px-4" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { data } from './_data.js'

import { LOCAL_STORAGE_ID } from '@/utils/relatorioParceiros'
import { mapActions } from 'vuex'

export default {
  name: 'ParceiroListFiltroConfig',
  data,
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    clear() {
      this.optionsSelected.splice(0)
      localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify([]))
      this.successMessage('Configuração reiniciada com sucesso!')
    },
    save() {
      localStorage.setItem(
        LOCAL_STORAGE_ID,
        JSON.stringify(this.optionsSelected)
      )
      this.close()
      this.successMessage('Configuração salva com sucesso!')
    },
    close() {
      this.$emit('close')
    },
  },
  created() {
    this.optionsSelected =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
}
</script>
