<template>
  <div>
    <v-col>
      <v-autocomplete
        v-model="model"
        label="Serviços"
        multiple
        :items="servicosSelecionados"
        item-text="descricao"
        return-object
      ></v-autocomplete>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ParceiroListFiltroModalItemServicos',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    parceiro: {
      type: [Object, String],
      required: true,
    },
  },
  async created() {
    const proprietario = this.empresaAtual.gate
      ? this.empresaAtual.public_id
      : this.parceiro.public_id
    await this.getServicos({
      proprietario,
    })
  },

  computed: {
    ...mapState('patio', ['servicos']),
    ...mapState('auth', ['empresaAtual']),
    servicosSelecionados() {
      return this.servicos
        .filter((s) => this.parceiro?.parceria?.servicos?.includes(s.id))
        .map((s) => {
          return {
            ...s,
            descricao: s.descricao.toUpperCase(),
          }
        })
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions('patio', ['getServicos']),
  },
}
</script>
